import React from 'react';
import Card from '../components/Card';
import Layout from '../components/layout/Layout';

const PaymentPolicy = () => (
  <Layout>
     <section className="py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Payment Policy</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card>
            <p className="font-semibold my-2 text-center text-xl">Subscriptions</p>
              <p className="my-3">
                Some parts of Service are billed on a subscription basis ("Subscription(s)").  You will be billed in advance on a recurring and periodic basis ("Billing Cycle").  Billing cycles will be set depending on the type of subscription plan you select when purchasing a Subscription.

                At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or IYG ENTERTAINMENT PVT LTD cancels it.  You may cancel your Subscription renewal either through your online account management page or by contacting info@iyg.co.in customer support team.

                A valid payment method is required to process the payment for your subscription.  You shall provide IYG ENTERTAINMENT PVT LTD with accurate and complete billing information that may include but not limited to full name, address, state, postal or zip code, telephone number, and a valid payment method information.  By submitting such payment information, you automatically authorize IYG ENTERTAINMENT PVT LTD to charge all Subscription fees incurred through your account to any such payment instruments.

                Should automatic billing fail to occur for any reason, IYG ENTERTAINMENT PVT LTD reserves the right to terminate your access to the Service with immediate effect.
              </p>
              <p className="font-semibold my-2 text-center text-xl">Free Trial</p>
              <p className="my-3">
                IYG ENTERTAINMENT PVT LTD may, at its sole discretion, offer a Subscription with a free trial for a limited period of time ("Free Trial").

                You may be required to enter your billing information in order to sign up for Free Trial.

                If you do enter your billing information when signing up for Free Trial, you will not be charged by IYG ENTERTAINMENT PVT LTD until Free Trial has expired. On the last day of Free Trial period, unless you cancelled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.

                At any time and without notice, IYG ENTERTAINMENT PVT LTD reserves the right to (i) modify Terms of Service of Free Trial offer, or (ii) cancel such Free Trial offer.
              </p>
              <p className="font-semibold my-2 text-center text-xl">Fee Changes</p>
              <p className="my-3">
                IYG ENTERTAINMENT PVT LTD, in its sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.

                IYG ENTERTAINMENT PVT LTD will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.

                Your continued use of Service after Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.
              </p>
              <p className="font-semibold my-2 text-center text-xl">Refunds</p>
              <p className="my-3">
                {/* We issue refunds for Contracts within 2 days of the original purchase of the Contract. If you want to request for refund please write us or email us to info@iyg.co.in */}
                We do not issue any refund on any of our product and services.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default PaymentPolicy;
